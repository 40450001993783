<template>
  <div>
    <b-modal
      :visible="isEventHandlerSidebarActive"
      :title="$t('Calendars')"
      size="lg"
      no-close-on-backdrop
      @change="(val) => $emit('update:is-event-handler-sidebar-active', val)"
    >
      <b-container fluid>
        <b-table-simple>
          <b-thead head-variant="primary">
            <b-tr>
              <b-td style="width: 20%;">
                {{ $t('Member') }}
              </b-td>
              <b-td style="width: 20%;">
                {{ $t('Room') }}
              </b-td>
              <b-td style="width: 20%;">
                {{ $t('Course / Package') }}
              </b-td>
            </b-tr>
          </b-thead>
          <b-tbody>
            <b-tr>
              <b-td>
                <v-select
                  id="register-memberUserInfo"
                  v-model="memberUserInfo"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="name"
                  :options="respDataMember"
                  :reduce="(respDataMember) => respDataMember._id"
                  :clearable="false"
                  class="select-size-lg"
                />
              </b-td>
              <b-td>
                <v-select
                  id="register-roomId"
                  v-model="extendedProps.roomId"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="name"
                  :options="respDataRoom"
                  :reduce="(respDataRoom) => respDataRoom._id"
                  :clearable="false"
                  class="select-size-lg"
                />
              </b-td>
              <b-td>
                <v-select
                  id="register-coursePackageId"
                  v-model="extendedProps.coursePackageId"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="name"
                  :options="respDataCoursePackage"
                  :reduce="(respDataCoursePackage) => respDataCoursePackage._id"
                  :clearable="false"
                  class="select-size-lg"
                />
              </b-td>
            </b-tr>
          </b-tbody>
        </b-table-simple>
        <b-form-group
          :label="$t('Title')"
          label-for="event-title"
        >
          <b-form-input
            id="event-title"
            v-model="title"

            autofocus
            trim
            :placeholder="$t('Event Title')"
          />
        </b-form-group>

        <b-form-group
          :label="$t('Start Date')"
          label-for="start-date"
        >
          <flat-pickr
            v-model="start"
            class="form-control"
            :config="configs"
          />
        </b-form-group>

        <b-form-group
          :label="$t('End Date')"
          label-for="end-date"
        >
          <flat-pickr
            v-model="end"
            class="form-control"
            :config="configs"
          />
        </b-form-group>

        <!-- All Day -->
        <b-form-group>
          <b-form-checkbox
            v-model="allDay"
            name="check-button"
            switch
            inline
          >
            {{ $t('All Day') }}
          </b-form-checkbox>
        </b-form-group>

        <!-- Textarea -->
        <b-form-group
          :label="$t('Description')"
          label-for="event-description"
        >
          <b-form-textarea
            id="event-description"
            v-model="extendedProps.description"
          />
        </b-form-group>
        <b-form-group
          :label="$t('Color')"
          label-for="event-color"
        >
          <b-row>
            <b-col cols="6">
              <b-form-input
                id="event-color"
                v-model="extendedProps.color"
                placeholder="Color"
                type="color"
              />
            </b-col>
            <b-col cols="6">
              <b-form-input
                id="event-color"
                v-model="extendedProps.color"
                placeholder="Color"
              />
            </b-col>
          </b-row>
        </b-form-group>
        <b-form-group>
          <b-form-checkbox
            v-model="extendedProps.paid"
            name="check-button"
            switch
            inline
          >
            {{ $t('Paid') }}
          </b-form-checkbox>
        </b-form-group>
        <b-table-simple>
          <b-thead head-variant="primary">
            <b-tr>
              <b-td style="width: 20%;">
                {{ $t('Room Price') }}
              </b-td>
              <b-td>{{ $t('Course / Package Price') }}</b-td>
              <b-td>{{ $t('Option Price') }}</b-td>
              <b-td>{{ $t('Sum Price') }}</b-td>
            </b-tr>
          </b-thead>
          <b-tbody>
            <b-tr>
              <b-td>{{ extendedProps.roomPrice }} </b-td>
              <b-td>{{ extendedProps.coursePackagePrice }}</b-td>
              <b-td>{{ extendedProps.optionPrice }}</b-td>
              <b-td>{{ extendedProps.sumPrice }}</b-td>
            </b-tr>
          </b-tbody>
        </b-table-simple>

      </b-container>

      <template #modal-footer>
        <div class="w-100">
          <b-button
            v-if="id !== null"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="danger"
            size="sm"
            class="float-left mb-0"
            @click="removeEvent"
          >{{ $t('Delete') }}</b-button>

          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            type="reset"
            variant="outline-secondary"
            size="sm"
            class="float-right"
            @click="$emit('update:is-event-handler-sidebar-active', false)"
          >{{ $t('Cancel') }}</b-button>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            size="sm"
            class="float-right mr-2"
            @click="validationForm"
          >{{ id !== null ? $t('Update') : $t('Add') }}</b-button>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import {
  BTableSimple,
  BThead,
  BTbody,
  BTr,
  BTd,
  BModal,
  VBModal,
  BContainer,
  BRow,
  BCol,
  BFormSelect,
  BForm,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BFormTextarea,
  BButton,
  BFormInvalidFeedback,
  BDropdown,
  BDropdownItem,
} from 'bootstrap-vue'
import store from '@/store'
import vSelect from 'vue-select'

import flatPickr from 'vue-flatpickr-component'
import Ripple from 'vue-ripple-directive'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

// eslint-disable-next-line import/extensions
import { Thai } from 'flatpickr/dist/l10n/th.js'
// import formValidation from '@core/comp-functions/forms/form-validation'
// import { ref, toRefs } from '@vue/composition-api'

export default {
  components: {
    BTableSimple,
    BThead,
    BTbody,
    BTr,
    BTd,
    BButton,
    BModal,
    BContainer,
    // eslint-disable-next-line vue/no-unused-components
    BRow,
    // eslint-disable-next-line vue/no-unused-components
    BCol,
    // eslint-disable-next-line vue/no-unused-components
    BFormSelect,
    // eslint-disable-next-line vue/no-unused-components
    BForm,
    // eslint-disable-next-line vue/no-unused-components
    BFormGroup,
    // eslint-disable-next-line vue/no-unused-components
    BFormInput,
    // eslint-disable-next-line vue/no-unused-components
    BFormCheckbox,
    // eslint-disable-next-line vue/no-unused-components
    BFormTextarea,
    // eslint-disable-next-line vue/no-unused-components
    vSelect,
    // eslint-disable-next-line vue/no-unused-components
    flatPickr,
    // eslint-disable-next-line vue/no-unused-components
    ValidationProvider,
    // eslint-disable-next-line vue/no-unused-components
    BFormInvalidFeedback,
    BDropdown,
    BDropdownItem,
    // eslint-disable-next-line vue/no-unused-components
    ValidationObserver,

  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  model: {
    prop: 'isEventHandlerSidebarActive',
    event: 'update:is-event-handler-sidebar-active',
  },
  props: {
    storeModuleName: {
      type: String,
      required: true,
    },
    storeModuleNameMember: {
      type: String,
      required: true,
    },
    storeModuleNameCoursePackage: {
      type: String,
      required: true,
    },
    storeModuleNameRoom: {
      type: String,
      required: true,
    },
    isEventHandlerSidebarActive: {
      type: Boolean,
      required: true,
    },
    eventData: {
      type: Object,
      required: true,
    },
    removeEventInCalendar: {
      type: Function,
      required: true,
    },
    refetchEvents: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      isCalendarListHandlerSidebarActive: false,
      event: {},
      configs: {
        enableTime: true,
        dateFormat: 'd-m-Y H:i',
        locale: Thai,
      },
      memberUserInfo: null,
      id: null,
      title: '',
      start: '',
      end: '',
      allDay: false,
      extendedProps: {
        userId: null,
        userInfo: null,
        roomId: null,
        coursePackageId: null,
        description: '',
        color: '#ffffff',
        show: true,
        paid: false,
        sumPrice: 0,
        roomPrice: 0,
        coursePackagePrice: 0,
        optionPrice: 0,
      },
      // list: [],
      required,
    }
  },
  computed: {
    tableColumns() {
      return [

        { key: 'title', label: this.$t('Title'), sortable: false },

        // { key: 'firstName', label: this.$t('FirstName'), sortable: false },
        // { key: 'lastName', label: this.$t('LastName'), sortable: false },
        { key: 'action', label: this.$t('ACTION'), sortable: false },
      ]
    },
    list() {
      return store.state[this.storeModuleName].list != null ? store.state[this.storeModuleName].list : []
    },
    respDataMember() {
      return store.state[this.storeModuleNameMember].respData != null
        ? store.state[this.storeModuleNameMember].respData.data.map(e => ({
          _id: e._id,
          userId: e.userId._id,
          name: `${e.firstName} ${e.lastName}`,
        }))
        : []
    },
    respDataCoursePackage() {
      return store.state[this.storeModuleNameCoursePackage].respData != null
        ? store.state[this.storeModuleNameCoursePackage].respData.data.map(e => ({
          _id: e._id,
          name: this.showFromCurrentLanguage(e.name),
        }))
        : []
    },
    respDataRoom() {
      return store.state[this.storeModuleNameRoom].respData != null
        ? store.state[this.storeModuleNameRoom].respData.data.map(e => ({
          _id: e._id,
          name: this.showFromCurrentLanguage(e.name),
        }))
        : []
    },
  },
  watch: {
    memberUserInfo(val) {
      if (val) {
        console.log(val)
        const index = this.respDataMember.findIndex(e => e._id.toString() === val.toString())
        if (index > -1) {
          this.extendedProps.userId = this.respDataMember[index].userId
          this.extendedProps.userInfo = val
        }
      }
    },
    isEventHandlerSidebarActive(val) {
      if (val) {
        if (Object.entries(this.eventData).length === 0) {
          this.initValues()
        } else {
          const {
            _id,
            id,
            title,
            start,
            end,
            allDay,
            url,
            extendedProps,
          } = this.eventData

          this.dataId = extendedProps._id
          this.id = id
          this.title = title
          this.start = start
          this.end = end
          this.allDay = allDay
          this.url = url
          this.extendedProps = extendedProps
          if (extendedProps.userInfo) {
            this.memberUserInfo = extendedProps.userInfo
          }
        }
      }
    },
  },
  methods: {
    showFromCurrentLanguage(data) {
      const indexLang = data.findIndex(e => e.lang === this.$i18n.locale)
      if (indexLang > -1) {
        return data[indexLang].value
      }
      return ''
    },
    removeEvent() {
      const eventId = this.id
      if (eventId !== null) {
        this.$bvModal
          .msgBoxConfirm(`${this.$t('Do you want to delete')}?`, {
            title: this.$t('Please Confirm'),
            cancelVariant: 'outline-secondary',
            okVariant: 'danger',
            okTitle: this.$t('Yes'),
            cancelTitle: this.$t('No'),
            centered: true,
          })
          .then(value => {
            if (value) {
              store
                .dispatch(`${this.storeModuleName}/removeEvent`, {
                  id: eventId,
                  _id: this.dataId,
                })
                .then(() => {
                  this.$emit('update:is-event-handler-sidebar-active', false)
                  this.removeEventInCalendar(eventId)
                })
            }
          })
      }
    },
    initValues() {
      this._id = null
      this.id = null
      this.title = ''
      this.start = ''
      this.end = ''
      this.allDay = false
      this.url = ''
      this.extendedProps = {
        userId: null,
        userInfo: null,
        roomId: null,
        coursePackageId: null,
        description: '',
        color: '#ffffff',
        show: true,
        paid: false,
        sumPrice: 0,
        roomPrice: 0,
        coursePackagePrice: 0,
        optionPrice: 0,
      }
    },

    validationForm() {
      const obj = {
        _id: this._id,
        id: this.id,
        title: this.title,
        start: this.start,
        end: this.end,
        allDay: this.allDay,
        url: this.url,
        extendedProps: this.extendedProps,
      }
      this.$emit('update:show', true)
      if (this.id !== null) {
        store
          .dispatch(`${this.storeModuleName}/updateEvent`, obj)
          .then(result => {
            this.$emit('update:is-event-handler-sidebar-active', false)
            this.$emit('update:show', false)
            console.log('fetch Success : ', result)

            this.refetchEvents()

            // this.showToast('success', 'bottom-right')
          })
          .catch(error => {
            console.log('fetchUsers Error : ', error)
            this.$toast({
              component: ToastificationContent,
              position: 'bottom-right',
              props: {
                title: this.$t('Error'),
                icon: 'ErrorIcon',
                variant: 'danger',
                text: this.$t(error.response.data.message),
              },
            })
            this.$emit('update:show', false)
            // this.showToast('danger', 'bottom-right')
          })
      } else {
        delete obj.id
        store
          .dispatch(`${this.storeModuleName}/addEvent`, obj)
          .then(result => {
            this.$emit('update:is-event-handler-sidebar-active', false)
            this.$emit('update:show', false)
            console.log('fetch Success : ', result)

            this.refetchEvents()

            // this.showToast('success', 'bottom-right')
          })
          .catch(error => {
            console.log('fetchUsers Error : ', error)
            this.$toast({
              component: ToastificationContent,
              position: 'bottom-right',
              props: {
                title: this.$t('Error'),
                icon: 'ErrorIcon',
                variant: 'danger',
                text: this.$t(error.response.data.message),
              },
            })
            this.$emit('update:show', false)
            // this.showToast('danger', 'bottom-right')
          })
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
